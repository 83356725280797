import "./SignUpButton.css";

const SignUpButton = () => {
  return (
    <div>
      <a href="/contact-us" className="signUpButton">
        Sign Up!
      </a>
    </div>
  );
};

export default SignUpButton;
